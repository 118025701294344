import { Component, inject } from "@angular/core";
import { Router } from "@angular/router";
import { ApiService } from "@app/api/api.service";
import {
  AuthService,
  ConnectionStatus,
  NetworkService,
  OfflineQueueService,
} from "@app/services";
import { OfflineQueueApiActions } from "@app/state";
import {
  IonApp,
  IonContent,
  IonHeader,
  IonIcon,
  IonItem,
  IonList,
  IonMenu,
  IonRouterOutlet,
  IonText,
  IonTitle,
  IonToolbar,
  MenuController,
  Platform,
} from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";
import { format } from "date-fns";
import { addIcons } from "ionicons";
import {
  businessOutline,
  documentOutline,
  listOutline,
  logOutOutline,
  settingsOutline,
  syncOutline,
  trashOutline,
  wifiOutline,
} from "ionicons/icons";

export const lines = "inset";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  standalone: true,
  imports: [
    IonApp,
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonList,
    IonItem,
    IonIcon,
    IonText,
    IonRouterOutlet,
  ],
})
export class AppComponent {
  private menu = inject(MenuController);
  private store = inject(Store);
  private router = inject(Router);
  private platform = inject(Platform);
  private apiService = inject(ApiService);
  private authService = inject(AuthService);

  // TODO maybe subscribe network changes in constructor?
  private syncService = inject(OfflineQueueService);
  private networkService = inject(NetworkService);

  constructor() {
    addIcons({
      listOutline,
      documentOutline,
      trashOutline,
      syncOutline,
      settingsOutline,
      businessOutline,
      wifiOutline,
      logOutOutline,
    });
    this.initializeApp();
  }

  status = this.networkService.getStatus();

  initializeApp() {
    this.overrideDate();

    this.apiService.syncEnqueue.subscribe((enqueuedGroup) => {
      this.store.dispatch(
        OfflineQueueApiActions.enqueueRequestSuccess({ enqueuedGroup })
      );
    });

    this.apiService.syncStarted.subscribe((enqueuedGroup) => {
      this.store.dispatch(
        OfflineQueueApiActions.processEnqueuedGroup({ enqueuedGroup })
      );
    });

    this.apiService.syncSuccess.subscribe((enqueuedGroup) => {
      this.store.dispatch(
        OfflineQueueApiActions.processEnqueuedGroupSuccess({ enqueuedGroup })
      );
    });

    this.apiService.syncFailure.subscribe((enqueuedGroup) => {
      this.store.dispatch(
        OfflineQueueApiActions.processEnqueuedGroupFailure({ enqueuedGroup })
      );
    });

    this.apiService.createSuccess.subscribe(({ model, data }) => {
      this.store.dispatch(
        OfflineQueueApiActions.ApiCreateSuccess({
          model: model,
          data: data,
        })
      );
    });

    this.apiService.updateSuccess.subscribe(({ model, data }) => {
      this.store.dispatch(
        OfflineQueueApiActions.ApiUpdateSuccess({
          model: model,
          data: data,
        })
      );
    });

    this.apiService.deleteSuccess.subscribe(({ model, id }) => {
      this.store.dispatch(
        OfflineQueueApiActions.ApiDeleteSuccess({
          model: model,
          id: id,
        })
      );
    });
  }

  overrideDate() {
    /**
     * Override date in backend format
     */
    Date.prototype.toDateString = function () {
      return format(this, "yyyy-MM-dd");
    };
  }

  onClickOperations() {
    this.router.navigate(["/operations"]);
    this.menu.close();
  }

  onClickAttachments() {
    this.router.navigate(["/attachments"]);
    this.menu.close();
  }

  onClickSettings() {
    this.router.navigate(["/settings"]);
    this.menu.close();
  }

  onClickCompany() {
    this.router.navigate(["/", "settings", "company", "view"]);
    this.menu.close();
  }

  onClickLogout() {
    // TODO dispatch action?
    this.authService.logout();
    this.menu.close();
  }

  async networkToggle() {
    if (this.networkService.getStatus() == ConnectionStatus.Online) {
      await this.networkService.updateNetworkStatus(ConnectionStatus.Offline);
    } else {
      await this.networkService.updateNetworkStatus(ConnectionStatus.Online);
    }
  }
}
