import { HttpClient } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { ApiId, IAppRecord } from "@app/models";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class OdooService {
  private http = inject(HttpClient);
  private authService = inject(AuthService);

  constructor() {}

  private getContext(context = {}) {
    return JSON.stringify({
      allowed_company_ids: [this.authService.getCompany()],
      ...context,
      dopa_app: 1,
    });
  }

  public create(
    model: string,
    values: any,
    context = {}
  ): Observable<IAppRecord> {
    if ("id" in values) {
      delete values.id;
    }
    return this.http.post<any>(`${environment.apiUrl}/${model}`, values, {
      params: { context: this.getContext(context) },
    });
  }

  public read(model: string, id: ApiId, context = {}): Observable<IAppRecord> {
    return this.http.get<any>(`${environment.apiUrl}/${model}/${id}`, {
      params: { context: this.getContext({ ...context, active_test: 0 }) },
    });
  }

  public search(model: string, context = {}): Observable<IAppRecord[]> {
    return this.http
      .get<any>(`${environment.apiUrl}/${model}`, {
        params: {
          context: this.getContext(context),
        },
      })
      .pipe(
        map((odooData) => (odooData.results.length > 0 ? odooData.results : []))
      );
  }

  public update(
    model: string,
    id: ApiId,
    values: any,
    context = {}
  ): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/${model}/${id}`, values, {
      params: { context: this.getContext(context) },
    });
  }

  public delete(model: string, id: ApiId, context = {}) {
    return this.http.delete<any>(`${environment.apiUrl}/${model}/${id}`, {
      params: { context: this.getContext(context) },
    });
  }

  public call(model: string, method: string, context = {}) {
    return this.http.get<any>(`${environment.apiUrl}/${model}/0/${method}`, {
      params: { context: this.getContext(context) },
    });
  }

  public file(id: any, context = {}) {
    return this.http.get<any>(`${environment.apiUrl}/ir.attachment/${id}`, {
      params: {
        include_fields: ["datas", "name", "id"],
        exclude_fields: ["*"],
        context: this.getContext(context),
      },
    });
  }
}
