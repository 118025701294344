<ion-app>
  <ion-menu menuId="side-menu" contentId="main" swipe-gesture="false">
    <ion-header>
      <ion-toolbar [color]="!!status ? 'dark' : 'primary'">
        <ion-title>Menu</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <ion-list>
        <ion-item button="true" (click)="onClickOperations()">
          <ion-icon
            aria-hidden="true"
            name="list-outline"
            slot="start"
          ></ion-icon>
          <ion-text> Dive Operations </ion-text>
        </ion-item>
        <ion-item button="true" (click)="onClickAttachments()">
          <ion-icon name="document-outline" slot="start"></ion-icon>
          <ion-text> Attachments </ion-text>
        </ion-item>
        <ion-item button="true" (click)="onClickSettings()">
          <ion-icon name="settings-outline" slot="start"></ion-icon>
          <ion-text> Settings </ion-text>
        </ion-item>
        <ion-item button="true" (click)="onClickCompany()">
          <ion-icon name="business-outline" slot="start"></ion-icon>
          <ion-text> Company </ion-text>
        </ion-item>
        <ion-item button="true" (click)="networkToggle()">
          <ion-icon name="wifi-outline" slot="start"></ion-icon>
          <ion-text> Network </ion-text>
        </ion-item>
        <ion-item button="true" (click)="onClickLogout()">
          <ion-icon name="log-out-outline" slot="start"></ion-icon>
          <ion-text> Sign Out </ion-text>
        </ion-item>
      </ion-list>
    </ion-content>
  </ion-menu>
  <ion-router-outlet id="main"></ion-router-outlet>
</ion-app>
